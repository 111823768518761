var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"row"},[_c('nav',{staticClass:"navbar navbar-fixed blue darken-4"},[_c('div',{staticClass:"container"},[_vm._m(0),_vm._m(1),_c('ul',{staticClass:"hide-on-med-and-down left",attrs:{"id":"nav-mobile"}},[_c('li',[_c('div',{staticClass:"col s12 m12",attrs:{"data-position":"right"}},[_c('router-link',{attrs:{"to":"/","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('span',{staticStyle:{"color":"white","cursor":"pointer","font-size":"16px"},attrs:{"role":"link"},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_vm._v(" INICIO ")])]}}])})],1)]),_c('li',[_c('div',{staticClass:"col s12 m12"},[_c('router-link',{attrs:{"to":"/AcercaDe","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('span',{staticStyle:{"color":"white","cursor":"pointer","font-size":"16px"},attrs:{"role":"link"},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_vm._v(" ACERCA DE ")])]}}])})],1)]),_c('li',[_c('div',{staticClass:"col s12 m12"},[_c('router-link',{attrs:{"to":"/Salidas","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('span',{staticStyle:{"color":"white","cursor":"pointer","font-size":"16px"},attrs:{"role":"link"},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_vm._v(" SALIDA SEMANAL ")])]}}])})],1)]),_c('li',[_c('div',{staticClass:"col s12 m12"},[_c('router-link',{attrs:{"to":"/Encomiendas","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('span',{staticStyle:{"color":"white","cursor":"pointer","font-size":"16px"},attrs:{"role":"link"},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_vm._v(" ENCOMIENDAS ")])]}}])})],1)])]),_c('ul',{staticClass:"hide-on-med-and-down right",attrs:{"id":"nav-mobile"}},[_c('li',[_c('div',{staticClass:"col s12 m12"},[_c('router-link',{attrs:{"to":"/Servicios","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('span',{staticStyle:{"color":"white","cursor":"pointer","font-size":"16px"},attrs:{"role":"link"},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_vm._v(" SERVICIOS ")])]}}])})],1)]),_c('li',[_c('div',{staticClass:"col s12 m12"},[_c('router-link',{attrs:{"to":"/Agentes","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('span',{staticStyle:{"color":"white","cursor":"pointer","font-size":"16px"},attrs:{"role":"link"},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_vm._v(" AGENTES ")])]}}])})],1)]),_c('li',[_c('div',{staticClass:"col s12 m12"},[_c('router-link',{attrs:{"to":"/Terminos","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('span',{staticStyle:{"color":"white","cursor":"pointer","font-size":"16px"},attrs:{"role":"link"},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_vm._v(" TERMINOS Y CONDICIONES ")])]}}])})],1)])])])]),_c('menutablets')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"left"},[_c('a',{staticClass:"sidenav-trigger",staticStyle:{"color":"white"},attrs:{"href":"#","data-target":"mobile-demo"}},[_c('i',{staticClass:"material-icons"},[_vm._v("menu")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"center"},[_c('a',{staticClass:"brand-logo"},[_c('img',{staticClass:"responsive-img",staticStyle:{"width":"120px"},attrs:{"src":require("@/assets/imagenes/logomiron3.png")}})])])}]

export { render, staticRenderFns }