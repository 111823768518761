//* @\store\index.js
//* 
//* Script del vuex (almacén de datos).
//* 
//* Desarrollado por Juan Carlos Jocop.
//* Octubre, 2021

import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

//? ................................................................................................
const mutations= {}

// import moduloInicio       from './modules/generales/mod_inicio.js'


//? Módulos de Parámetros Generales
import moduloInicio       from './modules/especificos/mod_inicio.js'

import moduloAcercaDe     from './modules/generales/mod_acercade.js'
import moduloSalidas      from './modules/especificos/mod_salidas.js'
import moduloEncomiendas  from './modules/especificos/mod_encomiendas.js'

import moduloServicios    from './modules/generales/mod_servicios.js'
import moduloTerminos     from './modules/generales/mod_terminos.js'

import moduloConocenos    from './modules/generales/mod_conocenos.js'

import moduloOtros        from './modules/generales/mod_otros.js'
import moduloAgentes      from './modules/generales/mod_agentes.js'

export default new Vuex.Store({
  modules:{

    moduloInicio,
    moduloAcercaDe,
    moduloSalidas,
    moduloEncomiendas,

    moduloServicios,
    moduloTerminos,

    moduloConocenos,
    moduloOtros,
    moduloAgentes,
  },
  state:{

    //? Para usar las funciones de Javascript que están en @/store/funciones.js
    st_lib: "hola que tal",                 //* Este es necesario para acceder a las funciones de node.js
    st_lib: require("@/store/funciones.js"),
    formatos: require("@/store/formatos.js"),
    paginear: require("@/store/paginacion.js"),
  },
  getters:{
  },
  mutations,
})
