//* @\store\modules\generales\mod_Acercade.js
//* @\store\catalogos\mod_Acercade.js
//* 
//* Script para las pestañas que tendrán los productos
//* 
//* Desarrollado por Juan Carlos Jocop.
//* Octubre, 2022

import {

    storage,
    dbAcercade,
} from '@/store/myFirebaseApp'
  
const refImagenes = storage.ref();

//? El objeto que tendrá el catálogo de Proveedores.
const st_Acercade = [];
  
//? Las mutaciones para el catálogo de Proveedores.
const mutations = {

    cargue_Acercade: (state) => {

        dbAcercade
        .on('value', snapshot => 
            mutations.obtieneAcercade(state, snapshot.val() )
        );  
    },
    obtieneAcercade: (state,losRegistros) => {

        state.st_Acercade = [];

        for (let key in losRegistros) {

            state.st_Acercade.push({

                llave:        key,

                anuncioImagen: losRegistros[key].anuncio,

                textoPrincipal:losRegistros[key].textoPrincipal,
                textoInnovacion: losRegistros[key].textoInnovacion,
                textoHonestidad: losRegistros[key].textoHonestidad,
                textoTrabajo:   losRegistros[key].textoTrabajo,

                imagenInnovacion: losRegistros[key].imagenInnovacion,
                imagenHonestidad: losRegistros[key].imagenHonestidad,
                imagenTrabajo:    losRegistros[key].imagenTrabajo,
                
                senalado: 	false,
            });
        } //* for

        // console.log("Cargué datos de Acercade ",state.st_Acercade)
    }, //* obtieneAcercade
   
};
  
const actions = {

    cargue_Acercade(context){
        context.commit('cargue_Acercade');
    },

    grabar_Acercade(context,elRegistro){
        context.commit('grabar_Acercade',elRegistro);
    },
    actualizar_Acercade(context,elRegistro){
        context.commit('actualizar_Acercade',elRegistro);
    },  
};

//? Los getters para el catálogo de Proveedores.
const getters= {

    parametrosAcercade: (state) =>{

        return state.st_Acercade;
    }
}
  
  //? ......................................................
export default {
    state:{
        st_Acercade,
    },
    actions,
    mutations,
    getters,
}
  
  
  