//* @\store\modules/generales\mod_conocenos.js
//* 
//* Script para las imagenes e CONOCENOS
//* 
//* Desarrollado por Juan Carlos Jocop.
//* Noviembre, 2022

import {

    storage,
    dbInicioImagenes,
} from '@/store/myFirebaseApp'
  
const refImagenes = storage.ref();

//? El objeto que tendrá el catálogo de Proveedores.
const st_Conocenos = [];
  
//? Las mutaciones para el catálogo de Proveedores.
const mutations = {

    cargue_Conocenos: (state) => {

        // console.log("[cargue_Conocenos] ",dbInicioImagenes);

        dbInicioImagenes
        .on('value', snapshot => 
            mutations.obtieneConocenos(state, snapshot.val() )
        );  
    },
    obtieneConocenos: (state,losRegistros) => {

        state.st_Conocenos = [];

        for (let key in losRegistros) {

            state.st_Conocenos.push({

                llave:        key,

                imagen: losRegistros[key].imagen,
                imagenRuta: losRegistros[key].imagenRuta,

                senalado: 	false,
            });
        } //* for

        console.log("[cargue_Conocenos] Después del for ",state.st_Conocenos);

    }, //* obtieneConocenos    
};
  
const actions = {
    async cargue_Conocenos(context){
        await context.commit('cargue_Conocenos');
    },
};

//? Los getters para el catálogo de Proveedores.
const getters= {

    parametrosConocenos: (state) =>{

        return state.st_Conocenos;
    }
}
  
  //? ......................................................
export default {
    state:{
        st_Conocenos,
    },
    actions,
    mutations,
    getters,
}
  
  
  