//* @/views/Redessociales.js
//*
//* Script del componente @/views/Redessociales.vue.
//*
//* Desarrollado por Juan Carlos Jocop
//* Octubre, 2022

import { mapGetters } from 'vuex'

export default {
    name: 'contactanos',
    data(){
        return {
            tDireccion: '',
            tTelefonos: '',
            tCorreo: '',
        }
    },
    computed: {
        ...mapGetters(['parametrosOtros']),
        filtrar(){
			return this.parametrosOtros
                .filter((registro) => {

                    this.tDireccion= registro.direccion;
                    this.tTelefonos= registro.telefono;
                    this.tCorreo=     registro.correo;
                    
                    return registro;
                })
        },        
    },
    created() {

        this.cargarOtro();

        M.AutoInit();
    },
    methods: {
        cargarOtro(){

            //* Carga la información de la página de otro
            this.$store.dispatch('cargue_Otros');    
        },        
    }
}