//* ./@/views/Terminos.js
//*
//* Script del componente ./@/views/Terminos.vue.
//*
//* Desarrollado por Juan Carlos Jocop
//* Noviembre, 2022

import { mapGetters } from 'vuex'

export default {
  name: 'Salidas',

  data () {
    return {

      anioproximo: '',
      mesproximo: '',
      mesproximo2: '',
      mesproximo3: '',

      fechaproxima: '',
      fechaproxima2: '',
      fechaproxima3: '',

      horarioatencion: '',
      horarioatencion2: '',
      horarioatencion3: '',

      //?
      mesproximom: '',
      mesproximo2m: '',
      mesproximo3m: '',

      fechaproximam: '',
      fechaproxima2m: '',
      fechaproxima3m: '',

      //? ...............................................
    }
  },

  computed:{
    ...mapGetters(['parametrosSalidas']),
    datosSalidas(){
      return this.parametrosSalidas
        .filter((registro) => {

          this.anioproximo= registro.anioproximo;
          
          this.mesproximo= registro.mesproximo;
          this.mesproximo2= registro.mesproximo2;
          this.mesproximo3= registro.mesproximo3;

          this.fechaproxima=registro.fechaproxima;
          this.fechaproxima2=registro.fechaproxima2;
          this.fechaproxima3=registro.fechaproxima3;

          this.horarioatencion= registro.horarioatencion;
          this.horarioatencion2=registro.horarioatencion2;
          this.horarioatencion3=registro.horarioatencion3;

          //?
          this.mesproximom= registro.mesproximom;
          this.mesproximo2m= registro.mesproximo2m;
          this.mesproximo3m= registro.mesproximo3m;

          this.fechaproximam=registro.fechaproximam;
          this.fechaproxima2m=registro.fechaproxima2m;
          this.fechaproxima3m=registro.fechaproxima3m;

          return registro;
      })
    },  
  },  
  mounted(){

    M.AutoInit();

    this.cargarSalidas();
},
  methods:{
    cargarSalidas(){
      
        //* Carga la información de la página de inicio
        this.$store.dispatch('cargue_Salidas')
    },

    //? -------------------------------------------------------------

  },
} 
