//* .\@\app_navbarvue.js
//* 
//* Script del componente .\@\app_navbarvue.vue 
//*
//* Desarrollado por Lucía De León Madrid.
//* Agosto, 2021

import menutablets  from '@/app_menutablets.vue';

export default {

    name: 'navbar',
    components: {
        menutablets,
    },
}