//* @\store\modules\generales\mod_Encomiendas.js
//* @\store\catalogos\mod_Encomiendas.js
//* 
//* Script para las pestañas que tendrán los productos
//* 
//* Desarrollado por Juan Carlos Jocop.
//* Octubre, 2022

import {

    storage,
    dbEncomiendas,
} from '@/store/myFirebaseApp'
  
const refImagenes = storage.ref();

//? El objeto que tendrá el catálogo de Proveedores.
const st_Encomiendas = [];
  
//? Las mutaciones para el catálogo de Proveedores.
const mutations = {

    cargue_Encomiendas: (state) => {

        dbEncomiendas
        .on('value', snapshot => 
            mutations.obtieneEncomiendas(state, snapshot.val() )
        );  
    },
    obtieneEncomiendas: (state,losRegistros) => {

        state.st_Encomiendas = [];

        for (let key in losRegistros) {

            state.st_Encomiendas.push({

                llave:        key,

                imagen: losRegistros[key].imagen,

                textoPrincipal:losRegistros[key].textoPrincipal,
                
                senalado: 	false,
            });
        } //* for

        console.log("Cargué datos de Encomiendas ",state.st_Encomiendas)
    }, //* obtieneEncomiendas
   
};
  
const actions = {

    cargue_Encomiendas(context){
        context.commit('cargue_Encomiendas');
    },

    grabar_Encomiendas(context,elRegistro){
        context.commit('grabar_Encomiendas',elRegistro);
    },
    actualizar_Encomiendas(context,elRegistro){
        context.commit('actualizar_Encomiendas',elRegistro);
    },  
};

//? Los getters para el catálogo de Proveedores.
const getters= {

    parametrosEncomiendas: (state) =>{

        return state.st_Encomiendas;
    }
}
  
  //? ......................................................
export default {
    state:{
        st_Encomiendas,
    },
    actions,
    mutations,
    getters,
}
  
  
  