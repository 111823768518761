//* ./@/views/Home.js
//*
//* Script del componente ./@/views/Home.vue.
//*
//* Desarrollado por Juan Carlos Jocop
//* Noviembre, 2023

export default {
  name: 'Agentes',
  mounted(){

    M.AutoInit();
  },
} 
