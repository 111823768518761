<!-- //* ./@/app_menutablets.Vue
<!--//*
<!--//* Componente para la navegación y ruteo en dispositivos móviles
<!--//*
<!--//* Desarrollado por Juan Carlos Jocop.
<!--//* Noviembre, 2022 -->

<template id="menutablets">
    <div style="cursor:pointer">
        <ul class="sidenav left" id="mobile-demo">

            <br>
            <div class="col s12 l12 blue darken-4">
                <!--//? 1. INICIO -->
                <div class="col s12 m2">
                    <span style="color:white">
                        <router-link
                            to="/"
                            tag="li"
                            exact>
                                INICIO
                        </router-link>
                    </span>
                </div>
                <!--//? 2. ACERCA DE -->
                <div class="col s12 m2">
                    <span style="color:white">
                        <router-link
                            to="/AcercaDe"
                            tag="li"
                            exact>
                                ACERCA DE
                        </router-link>
                    </span>
                </div>
                <!--//? 3. SALIDA SEMANAL -->
                <div class="col s12 m2">
                    <span style="color:white">
                        <router-link
                            to="/Salidas"
                            tag="li"
                            exact>
                                SALIDA SEMANAL
                        </router-link>
                    </span>
                </div>
                <!--//? 4. ENCOMIENDAS -->
                <div class="col s12 m2">
                    <span style="color:white">
                        <router-link
                            to="/Encomiendas"
                            tag="li"
                            exact>
                                ENCOMIENDAS
                        </router-link>
                    </span>
                </div>
                <!--//? 5. SERVICIOS -->
                <div class="col s12 m2">
                    <span style="color:white">
                        <router-link
                            to="/Servicios"
                            tag="li"
                            exact>
                                SERVICIOS
                        </router-link>
                    </span>
                </div>
                <!--//? 6. TERMINOS Y CONDICIOINES -->
                <div class="col s12 m2">
                    <span style="color:white">
                        <router-link
                            to="/Terminos"
                            tag="li"
                            exact>
                                TERMINOS Y CONDICIONES
                        </router-link>
                    </span>
                </div>
            </div>


        </ul>
    </div>
</template>

<script>
     export default {
        name: 'menutablets',
     }     
</script>

