//* ./@/views/Home.js
//*
//* Script del componente ./@/views/Home.vue.
//*
//* Desarrollado por Juan Carlos Jocop
//* Noviembre, 2022


import { mapGetters } from 'vuex'

export default {
  name: 'Home',

  data () {
    return {

      tamanio: 370,
      cambio: false,

      tamanio2: 455,
      cambio2: false,

      anioproximo: '',
      mesproximo: '',
      mesproximo2: '',
      mesproximo3: '',

      fechaproxima: '',
      fechaproxima2: '',
      fechaproxima3: '',

      horarioatencion: '',
      horarioatencion2: '',
      horarioatencion3: '',

      //?
      mesproximom: '',
      mesproximo2m: '',
      mesproximo3m: '',

      fechaproximam: '',
      fechaproxima2m: '',
      fechaproxima3m: '',

      //? ...............................................
      inicio: {},
      
      imagen0: '',
      imagen1: '',
      imagen2: '',
      imagen3: '',
      imagen4: '',
      imagen5: '',

      textoImagen1A: '',
      textoImagen1B: '',
      textoImagen2A: '',
      textoImagen2B: '',
      textoImagen3A: '',
      textoImagen3B: '',
      textoImagen3C: '',
      textoImagen4A: '',
      textoImagen4B: '',
      textoImagen5A: '',
      textoImagen5B: '',
      textoImagen5C: '',

      anuncioImagen: true,
    } 
  }, 
  computed:{
    ...mapGetters(['parametrosConocenos',
                   'parametrosInicio','parametrosSalidas']), 
    imagenesInicio(){

      let conteo= 0;
      return this.parametrosConocenos
        .filter((registro) => {

          console.log(conteo,registro.imagenRuta);
          
          switch (conteo){
            case 0:
              this.imagen0=registro.imagenRuta;
              break;
            case 1:
              this.imagen1=registro.imagenRuta;
              break;
            case 2:
              this.imagen2=registro.imagenRuta;
              break;
            case 3:
              this.imagen3=registro.imagenRuta;
              break;
            case 4:
              this.imagen4=registro.imagenRuta;
              break;
            case 5:
              this.imagen5=registro.imagenRuta;
              break;              
          }

          conteo= conteo + 1;

          return registro;
        })
    },

    datosInicio(){

      console.log("Que viene en parametros inicio ? ",this.parametrosInicio);
      
      return this.parametrosInicio
        .filter((registro) => {

          this.anuncioImagen=registro.anuncioImagen;

          this.textoImagen1A= registro.textoImagen1A;
          this.textoImagen1B= registro.textoImagen1B;
          this.textoImagen2A= registro.textoImagen2A;
          this.textoImagen2B= registro.textoImagen2B;
          this.textoImagen3A= registro.textoImagen3A;
          this.textoImagen3B= registro.textoImagen3B;
          this.textoImagen3C= registro.textoImagen3C;
          this.textoImagen4A= registro.textoImagen4A;
          this.textoImagen4B= registro.textoImagen4B;
          this.textoImagen5A= registro.textoImagen5A;
          this.textoImagen5B= registro.textoImagen5B;
          this.textoImagen5C= registro.textoImagen5C;

          return registro;
      })
    },

    datosSalidas(){
      return this.parametrosSalidas
        .filter((registro) => {

          this.anioproximo= registro.anioproximo;
          
          this.mesproximo= registro.mesproximo;
          this.mesproximo2= registro.mesproximo2;
          this.mesproximo3= registro.mesproximo3;

          this.fechaproxima=registro.fechaproxima;
          this.fechaproxima2=registro.fechaproxima2;
          this.fechaproxima3=registro.fechaproxima3;

          this.horarioatencion= registro.horarioatencion;
          this.horarioatencion2=registro.horarioatencion2;
          this.horarioatencion3=registro.horarioatencion3;

          //?
          this.mesproximom= registro.mesproximom;
          this.mesproximo2m= registro.mesproximo2m;
          this.mesproximo3m= registro.mesproximo3m;

          this.fechaproximam=registro.fechaproximam;
          this.fechaproxima2m=registro.fechaproxima2m;
          this.fechaproxima3m=registro.fechaproxima3m;

          return registro;
      })
    },    
  },  
  mounted(){

    M.AutoInit();

    this.cargarInicio();

    this.cargarSalidas();
        
    var elems = document.querySelectorAll('.slider');
    M.Slider.init(elems, {height:475});

    // var elems = document.querySelectorAll('.materialboxed');
    // M.Materialbox.init(elems, {});     
},
  methods:{  
    chgtamanio(){
      // console.log(this.cambio,this.tamanio);
      if (!this.cambio){
        this.tamanio=770;
      } else{
        this.tamanio=370
      }
      this.cambio= !this.cambio
    },
    chgtamanio2(){
      // console.log(this.cambio2,this.tamanio2);
      if (!this.cambio2){
        this.tamanio2=770;
      } else{
        this.tamanio2=455;
      }
      this.cambio2= !this.cambio2
    },
    cerrarImagen(){
      this.anuncioImagen= false;
    }, 
    cargarInicio(){

      //* Carga la información de la página de inicio
      this.$store.dispatch('cargue_Conocenos')
      
      //* Carga la información de la página de inicio
      this.$store.dispatch('cargue_Inicio')
    },
    cargarSalidas(){
      
      //* Carga la información de la página de inicio
      this.$store.dispatch('cargue_Salidas')
    },
  },
} 
