//* @\store\myFirebaseApp.js
//*
//* Script para acceder a la configuración del proyecto FB que se usará en 
//* el software. 
//*   
//* Desarrollado por Juan Carlos Jocop.
//* Agosto, 2021

import firebase from "firebase";

// const firebaseConfig = {
//   apiKey: "AIzaSyCqGcaL64miBYG-N1uEIz2QL_9RfbVEgHs",
//   authDomain: "mironwebpage-98316.firebaseapp.com",
//   databaseURL: "https://mironwebpage-98316-default-rtdb.firebaseio.com",
//   projectId: "mironwebpage-98316",
//   storageBucket: "mironwebpage-98316.appspot.com",
//   messagingSenderId: "1002654194338",
//   appId: "1:1002654194338:web:0ac7292fdc2ff44eda15fb"
// };

//? PRUEBAS !!!
const firebaseConfig = {
  apiKey: "AIzaSyD_DhL935RIc81WwVHlXJcDzse5c5MtHIw",
  authDomain: "mironwebpage-a3d98.firebaseapp.com",
  databaseURL: "https://mironwebpage-a3d98-default-rtdb.firebaseio.com",
  projectId: "mironwebpage-a3d98",
  storageBucket: "mironwebpage-a3d98.appspot.com",
  messagingSenderId: "975306899314",
  appId: "1:975306899314:web:615a144c6a73b4e0ab67eb"
};

//? Initialize Firebasenom run
firebase.initializeApp(firebaseConfig);
// firebase.analytics();

//? Direccionamiento al almacenamiento de imagenes
export const storage = firebase.storage();

/** //? Direccionamiento a Firebase */
export const db = firebase.database(); // Accedemos a la Base de datos

//? ............................................................
//? Usuarios, bitácora y datos de configuración */
export const ruta             =  "/";

//? Conocenos */
export const rutaInicioImagenes=  '/especificos/inicioImagenes';
export const dbInicioImagenes=    db.ref(rutaInicioImagenes);

// export const rutaInicio=  '/generales/inicio';


//? :-:-:-:-:-:-:-:-:-:-:-:-:-:-:-:-:-:-:-:-:-:-:-:-
//? Inicio */
export const rutaInicio=  '/especificos/inicio';
export const dbInicio=    db.ref(rutaInicio);

//? Inicio */
export const rutaAcercade=  '/especificos/acercade';
export const dbAcercade=    db.ref(rutaAcercade);

//? Salidas */
export const rutaSalidas=  '/especificos/salidas';
export const dbSalidas=    db.ref(rutaSalidas);

//? Encomiendas */
export const rutaEncomiendas=  '/especificos/encomiendas';
export const dbEncomiendas=    db.ref(rutaEncomiendas);

//? Servicios */
export const rutaServicios=  '/especificos/servicios';
export const dbServicios=    db.ref(rutaServicios);

//? Terminos */
export const rutaTerminos=  '/especificos/terminos';
export const dbTerminos=    db.ref(rutaTerminos);

//? Otros Parámetros */
export const rutaOtros=  '/generales/otros';
export const dbOtros=    db.ref(rutaOtros);

//? Agentes */
export const rutaAgentes=  '/especificos/agentes';
export const dbAgentes=    db.ref(rutaAgentes);

//? :-:-:-:-:-:-:-:-:-:-:-:-:-:-:-:-:-:-:-:-:-:-:-:-

