import Vue          from 'vue'

import VueRouter    from 'vue-router'
import Home         from '../views/Home.vue'
import AcercaDe     from '../views/AcercaDe.vue'
import Salidas      from '../views/Salidas.vue'
import Encomiendas  from '../views/Encomiendas.vue'

import Servicios    from '../views/Servicios.vue'
import Agentes      from '../views/Agentes.vue'
import Terminos     from '../views/Terminos.vue'

import Contactanos from '../views/Contactenos.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/AcercaDe',
    name: 'AcercaDe',
    component: AcercaDe
  },
  {
    path: '/Salidas',
    name: 'Salidas',
    component: Salidas
  },
  {
    path: '/Encomiendas',
    name: 'Encomiendas',
    component: Encomiendas
  },

  {
    path: '/Servicios',
    name: 'Servicios',
    component: Servicios
  },
  {
    path: '/Agentes',
    name: 'Agentes',
    component: Agentes
  },  
  {
    path: '/Terminos',
    name: 'TerminosSalidas',
    component: Terminos
  },


  {
    path: '/contactanos',
    name: 'Contactanos',
    component: Contactanos
  },
]

const router = new VueRouter({
  routes
})

export default router
