//* @\store\modules\generales\mod_Agentes.js
//* @\store\catalogos\mod_Agentes.js
//* 
//* Script para las pestañas que tendrán los productos
//* 
//* Desarrollado por Juan Carlos Jocop.
//* Octubre, 2022

import {

    storage,
    dbAgentes,
} from '@/store/myFirebaseApp'
  
const refImagenes = storage.ref();

//? El objeto que tendrá el catálogo de Proveedores.
const st_Agentes = [];
  
//? Las mutaciones para el catálogo de Proveedores.
const mutations = {

    cargue_Agentes: (state) => {

        console.log("voy a cargar agentes !")
        dbAgentes
        .on('value', snapshot => 
            mutations.obtieneAgentes(state, snapshot.val() )
        );  
    },
    obtieneAgentes: (state,losRegistros) => {

        state.st_Agentes = [];

        for (let key in losRegistros) {

            state.st_Agentes.push({

                llave:        key,

                imagen: losRegistros[key].imagen,
                texto:  losRegistros[key].texto,

                senalado: 	false,
            });
        } //* for

        console.log("Cargué datos de agentes ",state.st_Agentes)
    }, //* obtieneAgentes
   
};
  
const actions = {

    cargue_Agentes(context){
        context.commit('cargue_Agentes');
    },
};

//? Los getters para el catálogo de Proveedores.
const getters= {

    parametrosAgentes: (state) =>{

        return state.st_Agentes;
    }
}
  
  //? ......................................................
export default {
    state:{
        st_Agentes,
    },
    actions,
    mutations,
    getters,
}
  
  
  