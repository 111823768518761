import { render, staticRenderFns } from "./Servicios.vue?vue&type=template&id=ee2f6452&scoped=true&"
import script from "./Servicios.js?vue&type=script&lang=js&"
export * from "./Servicios.js?vue&type=script&lang=js&"
import style0 from "./Servicios.vue?vue&type=style&index=0&id=ee2f6452&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ee2f6452",
  null
  
)

export default component.exports