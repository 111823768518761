//* ./@/views/Terminos.js
//*
//* Script del componente ./@/views/Terminos.vue.
//*
//* Desarrollado por Juan Carlos Jocop
//* Noviembre, 2022

import { mapGetters } from 'vuex'

export default {
  name: 'Salidas',

  data () {
    return {
      // source1: 'https://serviguaexpress.com/webpage/pdfs/Terminosycondiciones_mironexpress.pdf',
      source1: '',
    } 
  },
  computed:{
    ...mapGetters(['parametrosTerminos']),
    datosTerminos(){
      return this.parametrosTerminos
        .filter((registro) => {

          this.source1=registro.pdf;

          return registro;
      })
    },   },  
  mounted(){

    M.AutoInit();

    this.cargarTerminos();
  },
  methods:{   
    cargarTerminos(){
      
      this.$store.dispatch('cargue_Terminos')
    },      
  },
} 

