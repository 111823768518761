//* ./@/views/Home.js
//*
//* Script del componente ./@/views/Home.vue.
//*
//* Desarrollado por Juan Carlos Jocop
//* Febrero, 2023

import { mapGetters } from 'vuex'

export default {
  name: 'AcercaDe',

  data () {
    return {
      
      textoPrincipal: '',

      imagenInnovacion: '',
      textoInnovacion: '',
      imagenHonestidad: '',
      textoHonestidad: '',
      imagenTrabajo: '',
      textoTrabajo: ''
    } 
  }, 
  computed:{
    ...mapGetters(['parametrosAcercade']),
    datosAcercaDe(){
      return this.parametrosAcercade
        .filter((registro) => {

          this.textoPrincipal=registro.textoPrincipal;

          this.textoInnovacion=registro.textoInnovacion;
          this.imagenInnovacion=registro.imagenInnovacion;
          this.textoHonestidad=registro.textoHonestidad;
          this.imagenHonestidad=registro.imagenHonestidad;
          this.textoTrabajo=registro.textoTrabajo;
          this.imagenTrabajo=registro.imagenTrabajo;

          return registro;
      })
    },         
  },  
  mounted(){

    M.AutoInit();

    this.cargarAcercaDe();
},
  methods:{    
    cargarAcercaDe(){
      
      //* Carga la información de la página de inicio
      this.$store.dispatch('cargue_Acercade')
    },    
  },
} 
