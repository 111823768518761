//* @\store\modules\generales\mod_Terminos.js
//* @\store\catalogos\mod_Terminos.js
//* 
//* Script para las pestañas que tendrán los productos
//* 
//* Desarrollado por Juan Carlos Jocop.
//* Octubre, 2022

import {

    storage,
    dbTerminos,
} from '@/store/myFirebaseApp'
  
const refImagenes = storage.ref();

//? El objeto que tendrá el catálogo de Proveedores.
const st_Terminos = [];
  
//? Las mutaciones para el catálogo de Proveedores.
const mutations = {

    cargue_Terminos: (state) => {

        dbTerminos
        .on('value', snapshot => 
            mutations.obtieneTerminos(state, snapshot.val() )
        );  
    },
    obtieneTerminos: (state,losRegistros) => {

        state.st_Terminos = [];

        for (let key in losRegistros) {

            state.st_Terminos.push({

                llave:        key,

                // pdf: losRegistros[key].pdf,
                pdf: losRegistros[key].pdfRuta,
                
                senalado: 	false,
            });
        } //* for

        console.log("Cargué datos de Terminos ",state.st_Terminos)
    }, //* obtieneTerminos
   
};
  
const actions = {

    cargue_Terminos(context){
        context.commit('cargue_Terminos');
    },

    grabar_Terminos(context,elRegistro){
        context.commit('grabar_Terminos',elRegistro);
    },
    actualizar_Terminos(context,elRegistro){
        context.commit('actualizar_Terminos',elRegistro);
    },  
};

//? Los getters para el catálogo de Proveedores.
const getters= {

    parametrosTerminos: (state) =>{

        return state.st_Terminos;
    }
}
  
  //? ......................................................
export default {
    state:{
        st_Terminos,
    },
    actions,
    mutations,
    getters,
}
  
  
  