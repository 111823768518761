//* @\store\modules\generales\mod_Servicios.js
//* @\store\catalogos\mod_Servicios.js
//* 
//* Script para las pestañas que tendrán los productos
//* 
//* Desarrollado por Juan Carlos Jocop.
//* Octubre, 2022

import {

    storage,
    dbServicios,
} from '@/store/myFirebaseApp'
  
const refImagenes = storage.ref();

//? El objeto que tendrá el catálogo de Proveedores.
const st_Servicios = [];
  
//? Las mutaciones para el catálogo de Proveedores.
const mutations = {

    cargue_Servicios: (state) => {

        dbServicios
        .on('value', snapshot => 
            mutations.obtieneServicios(state, snapshot.val() )
        );  
    },
    obtieneServicios: (state,losRegistros) => {

        state.st_Servicios = [];

        for (let key in losRegistros) {

            state.st_Servicios.push({

                llave:        key,

                imagen: losRegistros[key].imagen,

                servicio1:losRegistros[key].servicio1,
                servicio2:losRegistros[key].servicio2,
                servicio3:losRegistros[key].servicio3,
                servicio4:losRegistros[key].servicio4,
                servicio5:losRegistros[key].servicio5,
                servicio6:losRegistros[key].servicio6,
                servicio7:losRegistros[key].servicio7,
                servicio8:losRegistros[key].servicio8,

                imagenServicio1: losRegistros[key].imagenServicio1,
                imagenServicio2: losRegistros[key].imagenServicio2,
                imagenServicio3: losRegistros[key].imagenServicio3,
                imagenServicio4: losRegistros[key].imagenServicio4,
                
                senalado: 	false,
            });
        } //* for

        console.log("Cargué datos de Servicios ",state.st_Servicios)
    }, //* obtieneServicios
   
};
  
const actions = {

    cargue_Servicios(context){
        context.commit('cargue_Servicios');
    },

    grabar_Servicios(context,elRegistro){
        context.commit('grabar_Servicios',elRegistro);
    },
    actualizar_Servicios(context,elRegistro){
        context.commit('actualizar_Servicios',elRegistro);
    },  
};

//? Los getters para el catálogo de Proveedores.
const getters= {

    parametrosServicios: (state) =>{

        return state.st_Servicios;
    }
}
  
  //? ......................................................
export default {
    state:{
        st_Servicios,
    },
    actions,
    mutations,
    getters,
}
  
  
  