//* @\store\modules\generales\mod_Salidas.js
//* @\store\catalogos\mod_Salidas.js
//* 
//* Script para las pestañas que tendrán los productos
//* 
//* Desarrollado por Juan Carlos Jocop.
//* Octubre, 2022

import {

    storage,
    dbSalidas,
} from '@/store/myFirebaseApp'
  
const refImagenes = storage.ref();

//? El objeto que tendrá el catálogo de Proveedores.
const st_Salidas = [];
  
//? Las mutaciones para el catálogo de Proveedores.
const mutations = {

    cargue_Salidas: (state) => {

        dbSalidas
        .on('value', snapshot => 
            mutations.obtieneSalidas(state, snapshot.val() )
        );  
    },
    obtieneSalidas: (state,losRegistros) => {

        state.st_Salidas = [];

        for (let key in losRegistros) {

            state.st_Salidas.push({

                llave:        key,

                anioproximo:    losRegistros[key].anioproximo,

                mesproximo: losRegistros[key].mesproximo,
                mesproximo2: losRegistros[key].mesproximo2,
                mesproximo3: losRegistros[key].mesproximo3,

                fechaproxima: losRegistros[key].fechaproxima,
                fechaproxima2: losRegistros[key].fechaproxima2,
                fechaproxima3: losRegistros[key].fechaproxima3,

                horarioatencion: losRegistros[key].horarioatencion,
                horarioatencion2: losRegistros[key].horarioatencion2,
                horarioatencion3: losRegistros[key].horarioatencion3,

                //?
                //?
                mesproximom:     losRegistros[key].mesproximom,
                fechaproximam:   losRegistros[key].fechaproximam,

                mesproximo2m:    losRegistros[key].mesproximo2m,
                fechaproxima2m:  losRegistros[key].fechaproxima2m,

                mesproximo3m:    losRegistros[key].mesproximo3m,
                fechaproxima3m:  losRegistros[key].fechaproxima3m,


                senalado: 	false,
            });
        } //* for

        console.log("Cargué datos de Salidas ",state.st_Salidas)
    }, //* obtieneSalidas
   
};
  
const actions = {

    cargue_Salidas(context){
        context.commit('cargue_Salidas');
    },

    grabar_Salidas(context,elRegistro){
        context.commit('grabar_Salidas',elRegistro);
    },
    actualizar_Salidas(context,elRegistro){
        context.commit('actualizar_Salidas',elRegistro);
    },  
};

//? Los getters para el catálogo de Proveedores.
const getters= {

    parametrosSalidas: (state) =>{

        return state.st_Salidas;
    }
}
  
  //? ......................................................
export default {
    state:{
        st_Salidas,
    },
    actions,
    mutations,
    getters,
}
  
  
  