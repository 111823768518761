//* ./@/views/Home.js
//*
//* Script del componente ./@/views/Home.vue.
//*
//* Desarrollado por Juan Carlos Jocop
//* Noviembre, 2022

import { mapGetters } from 'vuex'

export default {
  name: 'servicios',

  data () {
    return {

      nuevoRegistro:{
        servicio1: '',
        servicio2: '',
        servicio3: '',
        servicio4: '',
        imagenServicio1: '',
        imagenServicio2: '',
        imagenServicio3: '',
        imagenServicio4: '',
      },

      imagen: '',
    } 
  },
  computed:{
    ...mapGetters(['parametrosServicios']),
    datosServicios(){

      return this.parametrosServicios
        .filter((registro) => {

          this.nuevoRegistro.servicio1=registro.servicio1;
          this.nuevoRegistro.servicio2=registro.servicio2;
          this.nuevoRegistro.servicio3=registro.servicio3;
          this.nuevoRegistro.servicio4=registro.servicio4;

          this.nuevoRegistro.imagenServicio1=registro.imagenServicio1;
          this.nuevoRegistro.imagenServicio2=registro.imagenServicio2;
          this.nuevoRegistro.imagenServicio3=registro.imagenServicio3;
          this.nuevoRegistro.imagenServicio4=registro.imagenServicio4;

          return registro;
      })
    }, 
  },  
  created(){

    this.cargueServicios();
  },
  mounted(){

    M.AutoInit();
  },
  methods:{

    //? ................................................
    cargueServicios(){

      this.$store.commit('cargue_Servicios');
    },     
  },
} 
