//* @\store\modules\generales\mod_Inicio.js
//* @\store\catalogos\mod_Inicio.js
//* 
//* Script para las pestañas que tendrán los productos
//* 
//* Desarrollado por Juan Carlos Jocop.
//* Octubre, 2022

import {

    storage,
    dbInicio,
} from '@/store/myFirebaseApp'
  
const refImagenes = storage.ref();

//? El objeto que tendrá el catálogo de Proveedores.
const st_Inicio = [];
  
//? Las mutaciones para el catálogo de Proveedores.
const mutations = {

    cargue_Inicio: (state) => {

        dbInicio
        .on('value', snapshot => 
            mutations.obtieneInicio(state, snapshot.val() )
        );  
    },
    obtieneInicio: (state,losRegistros) => {

        state.st_Inicio = [];

        for (let key in losRegistros) {

            state.st_Inicio.push({

                llave:        key,

                anuncioImagen: losRegistros[key].anuncio,

                textoImagen1A: losRegistros[key].textoImagen1A,
                textoImagen1B: losRegistros[key].textoImagen1B,

                textoImagen2A: losRegistros[key].textoImagen2A,
                textoImagen2B: losRegistros[key].textoImagen2B,

                textoImagen3A: losRegistros[key].textoImagen3A,
                textoImagen3B: losRegistros[key].textoImagen3B,
                textoImagen3C: losRegistros[key].textoImagen3C,

                textoImagen4A: losRegistros[key].textoImagen4A,
                textoImagen4B: losRegistros[key].textoImagen4B,

                textoImagen5A: losRegistros[key].textoImagen5A,
                textoImagen5B: losRegistros[key].textoImagen5B,
                textoImagen5C: losRegistros[key].textoImagen5C,

                senalado: 	false,
            });
        } //* for

        // console.log("[cargue_Inicio] datos ",state.st_Inicio)
    }, //* obtieneInicio
   
};
  
const actions = {

    cargue_Inicio(context){
        context.commit('cargue_Inicio');
    },

    grabar_Inicio(context,elRegistro){
        context.commit('grabar_Inicio',elRegistro);
    },
    actualizar_Inicio(context,elRegistro){
        context.commit('actualizar_Inicio',elRegistro);
    },  
};

//? Los getters para el catálogo de Proveedores.
const getters= {

    parametrosInicio: (state) =>{

        return state.st_Inicio;
    }
}
  
  //? ......................................................
export default {
    state:{
        st_Inicio,
    },
    actions,
    mutations,
    getters,
}
  
  
  