//* ./@/views/Home.js
//*
//* Script del componente ./@/views/Home.vue.
//*
//* Desarrollado por Juan Carlos Jocop
//* Noviembre, 2022


import { mapGetters } from 'vuex'

export default {
  name: 'encomiendas',

  data () {
    return {
      imagen: '',
      textoPrincipal: '',

      //?
      tamanio: 200,
      cambioAg: false,

      li: 0,
      ls: 7,
      espejo: 0,      
    } 
  }, 
  computed:{
    ...mapGetters(['parametrosEncomiendas','parametrosAgentes']),
    datosEncomiendas(){
      return this.parametrosEncomiendas
        .filter((registro) => {

            this.imagen= registro.imagen;
            this.textoPrincipal= registro.textoPrincipal;

            return registro;
      })
    },         
  },  
  mounted(){

    var elems = document.querySelectorAll('.slider');
    M.Slider.init(elems, {height:850, indicators: true});

    M.AutoInit();

    this.cargarEncomiendas();

    this.cargarAgentes();    
},
  methods:{    
    cargarEncomiendas(){
      
      this.$store.dispatch('cargue_Encomiendas')
    },
    
    //?
    chgtamanio(){

      if (!this.cambioAg){
        this.tamanio=770;
      } else{
        this.tamanio=370
      }
      this.cambioAg= !this.cambioAg
    },
    cargarAgentes(){
      
      //* Carga la información de la página de inicio
      this.$store.dispatch('cargue_Agentes')
    },

    //?
    siguiente(){

      if ( (this.li>=0) && (this.ls+8 < this.parametrosAgentes.length)){

        this.li=this.li+8;
        this.ls=this.ls+8;
        this.espejo= this.li;
      } else{
        if (this.li+8 <= this.parametrosAgentes.length){
          console.log("... ",this.li+8)
          this.li=this.li+8;
          this.ls=this.parametrosAgentes.length;  
        }
      }
    },

    anterior(){

      if( this.li-8 < 0){
        console.log(".. then")
        this.li=0;
        this.ls=this.li + 7;
      } else {
        console.log(".... else")
        this.li= this.li - 8;
        this.ls= this.li + 7;
      }
    }    
  },
} 
